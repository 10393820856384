import { getStore } from '@caresend/ui-components';
import { computed } from 'vue';
import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

const routesHome: Array<RouteConfig> = [
  {
    path: '/:partnerName?/',
    name: routeNames.HOME,
    component: () => import(
      /* webpackChunkName: 'chunk-main' */
      /* webpackMode: 'eager' */
      '@/views/Home.vue'
    ),
    meta: {
      allowIncomplete: true,
      authRequired: true,
      loadUntilTrue: () => computed<boolean>(() =>
        !getStore().state.app.loadingIDs[routeNames.HOME],
      ),
      loadingIdTrackingEnabled: true,
      loadingMessage: () => 'Loading visits…',
    },
    props: true,
  },
];

export { routesHome };
