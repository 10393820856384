// Service worker was causing issues with users getting up to date versions of
// the app. For now we will rely on our header-based caching strategy. There
// will be a new, much more up to date Vite PWA plugin implemented in the Vue 3
// project, allowing us to reinstate the service worker in a more stable way
// when ready.

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => registration.unregister());
  });
}
