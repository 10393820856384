
import { DenormalizedDraftWaypoint } from '@caresend/types';
import { EmptyOrdersList, OrderWaypointCard, getStore, useDraftWaypointsStore } from '@caresend/ui-components';
import { computed, defineComponent, onMounted, watch } from 'vue';

import { routeNames } from '@/router/model';

export default defineComponent({
  name: 'VisitsList',
  components: {
    EmptyOrdersList,
    OrderWaypointCard,
  },
  setup() {
    const draftWaypointStore = useDraftWaypointsStore();
    const { getters } = useDraftWaypointsStore();
    const store = getStore();

    const visits = computed<DenormalizedDraftWaypoint[]>(() =>
      getters.getDenormalizedDraftWaypointsForPatientVisitList(),
    );

    onMounted(async () => {
      try {
        await draftWaypointStore.fetchDenormalizedDraftWaypoints();
      } finally {
        store.commit('app/SET_LOADING_IDS', { [routeNames.HOME]: false });
      }
    });

    const isLoading = computed<boolean>(() => draftWaypointStore.isLoading);

    watch(isLoading, (newIsLoading) => {
      store.commit(
        'app/SET_CUSTOM_GLOBAL_LOADING_MESSAGE',
        newIsLoading ? 'Loading visits…' : null,
      );
    }, { immediate: true });

    const visitsListIsEmpty = computed<boolean>(() =>
      !isLoading.value && !visits.value.length,
    );

    return {
      isLoading,
      visits,
      visitsListIsEmpty,
    };
  },
});
